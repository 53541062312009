import { BlockModel } from "../models";
import React, { useState } from "react";
import * as blockCss from "../styles/block.module.scss";
import { Block } from "./block";
import { DotContainer } from "./dotContainer";

interface SubblocksContainerProps {
  blocks: Array<BlockModel>;
  isSlideshow: boolean;
}

export const SubblocksContainer: React.FC<SubblocksContainerProps> = ({
  blocks,
  isSlideshow,
}) => {
  const [selectedSlideshowChild, setSelectedSlideshowChild] = useState(0);

  return (
    <>
      <div className={blockCss.children}>
        {blocks.map((s, i) => (
          <Block
            className={
              isSlideshow && selectedSlideshowChild === i && blockCss.Selected
            }
            key={s.blockName}
            {...s}
          />
        ))}
      </div>
      {isSlideshow && (
        <DotContainer
          blocks={blocks}
          selectedSlideshowChild={selectedSlideshowChild}
          setSelectedSlideshowChild={setSelectedSlideshowChild}
        />
      )}
    </>
  );
};
