import * as React from "react";
import { graphql } from "gatsby";
import "../styles/styles.scss";
import { Block } from "../components";
import { BlockModel, CompanyModel, PostPreviewModel, PostModel } from "../models";
import { Image } from "../components/image";
import Meta from "../components/meta";

const Page: React.FC<PageModel> = ({ data }) => {
    return (
        <>
            <Meta page={data.contentfulPage} company={data.contentfulCompany} />
            <header>
                <Image image={data.contentfulCompany.logo} />
            </header>

            <main>
                {data.contentfulPage.blocks.map((b, i) => (
                    <Block key={i} {...b} isHero={!i} posts={data.allContentfulPost.nodes} />
                ))}
            </main>

            <footer>
                <h1>#Say hello</h1>
                <div>
                    <div>
                        <h2>Email</h2>
                        <p>{data.contentfulCompany.emailAddress}</p>
                    </div>
                    <div>
                        <h2>Address</h2>
                        <p>{data.contentfulCompany.physicalAddress}</p>
                    </div>
                    <div>
                        <h2>Phone</h2>
                        <p>{data.contentfulCompany.mobilePhone}</p>
                    </div>
                    <div>
                        <p>{data.contentfulCompany.copyright}</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Page;

export interface PageModel {
    data: {
        contentfulPage: {
            blocks: Array<BlockModel>;
            meta: {
                title: string;
                description: string;
            };
            createdAt: string;
            updatedAt: string;
        };
        contentfulCompany: CompanyModel;
        allContentfulPost: { nodes: Array<PostPreviewModel> };
        contentfulPost: PostModel;
    };
}

export const query = graphql`
    query PageQuery($slug: String) {
        contentfulPage(slug: { eq: $slug }) {
            blocks {
                ...blockFields
            }
            meta {
                title
                description
            }
            createdAt
            updatedAt
        }
        contentfulCompany {
            ...companyFields
        }
        allContentfulPost {
            nodes {
                ...postPreviewFields
            }
        }
    }
`;
