import React, { useReducer, Reducer } from "react";
import { PostPreviewModel } from "../models/post-preview-model";
import { ImageContainer } from "./imageContainer";
import * as Css from "../styles/block.module.scss";
import { Button } from "./button";

interface IFeaturedPostPreview {
    posts: Array<PostPreviewModel>;
}

export const FeaturedPostPreview: React.FC<IFeaturedPostPreview> = ({ posts }) => {
    const loadMore = (state) => [...state, posts.slice(state.length * 3, state.length * 3 + 3)];

    const [postRows, loadNextPostRow] = useReducer<Reducer<Array<Array<PostPreviewModel>>, void>>(loadMore, [
        posts.slice(0, 3),
    ]);

    return (
        <>
            <div id={"post_container"}>
                {postRows.map((r, i) => (
                    <div key={i} className={Css.postContainer}>
                        {r.map((a) => (
                            <Post
                                key={a.slug}
                                postName={a.postName}
                                author={a.author}
                                date={a.date}
                                headerImage={a.headerImage}
                                slug={a.slug}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {posts.length > postRows.length * 3 && (
                <Button name={"Load More"} onclick={loadNextPostRow} align={"Center"} />
            )}
        </>
    );
};

const Post: React.FC<PostPreviewModel> = ({ postName, author, date, headerImage, slug }) => {
    const formatted_date = new Date(date).toLocaleDateString(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
    });

    const loadArticle = () => window.location.assign(`/article/${slug}`);

    return (
        <div className={Css.post} onClick={loadArticle}>
            <ImageContainer images={[headerImage]} className={Css.postImage} />
            <p className={Css.postName}>{postName}</p>
            <p>By {author}</p>
            <p className={Css.date}>{formatted_date}</p>
        </div>
    );
};
