import React from "react";
import classNames from "classnames";
import * as blockCss from "../styles/block.module.scss";
import * as heroCss from "../styles/hero.module.scss";
import { BlockModel, PostPreviewModel } from "../models";
import { ImageContainer } from "./imageContainer";
import { RichText } from "./richtext";
import { SubblocksContainer } from "./subblocksContainer";
import { FeaturedPostPreview } from "./featuredPostPreview";

interface BlockProps extends BlockModel {
    isHero?: boolean;
    className?: string;
    posts: Array<PostPreviewModel>;
}

export const Block: React.FC<BlockProps> = ({ isHero, className, posts, ...b }) => {
    const css = isHero ? heroCss : blockCss;
    const layoutClassName = isHero
        ? ""
        : Object.entries(b.layout || {})
              .map(([k, v]) => blockCss[`${k}${v}`])
              .join(" ")
              .trim();

    return (
        <section className={classNames(layoutClassName, className)}>
            <RichText component="h1" text={b.title} className={css.h1} />
            <RichText component="div" text={b.bigPoint} className={css.bigPoint} />
            <RichText text={b.text} className={css.text} />
            {b.images && (
                <ImageContainer
                    images={b.images}
                    isCarousel={!isHero && b.layout && b.layout.carousel}
                    className={css.imageContainer}
                    fit={isHero ? "fill" : b.layout.imageFit}
                    showDescription={b.layout && b.layout.displayImageDescription}
                />
            )}
            {b.postCollection && b.postCollection === true && <FeaturedPostPreview posts={posts} />}
            {!isHero && b.blocks && (
                <SubblocksContainer
                    blocks={b.blocks}
                    isSlideshow={b.layout && b.layout.displayChildren === "Slideshow"}
                />
            )}
        </section>
    );
};
