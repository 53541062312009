import React from "react";
import * as BlockCss from "../styles/block.module.scss";

type align_options = "Left" | "Center" | "Right";

interface IButton {
    name: string;
    onclick: any;
    align: align_options;
}

export const Button: React.FC<IButton> = ({ name, onclick, align }) => {
    const wrapperclass = `buttonWrapper${align}`;
    return (
        <div className={BlockCss[wrapperclass]}>
            <button onClick={onclick} className={BlockCss.button}>
                {name}
            </button>
        </div>
    );
};
