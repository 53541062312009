import { BlockModel } from "../models";
import React, { Dispatch, SetStateAction } from "react";
import classNames from "classnames";
import * as blockCss from "../styles/block.module.scss";

interface DotContainerProps {
  blocks: Array<BlockModel>;
  selectedSlideshowChild: number;
  setSelectedSlideshowChild: Dispatch<SetStateAction<number>>;
}

export const DotContainer: React.FC<DotContainerProps> = ({
  blocks,
  selectedSlideshowChild,
  setSelectedSlideshowChild,
}) => (
  <div className={blockCss.dotContainer}>
    {blocks.map((s, i) => (
      <span
        key={i}
        className={classNames(
          blockCss.indicator,
          selectedSlideshowChild === i && blockCss.indicatorSelected
        )}
        onClick={() => setSelectedSlideshowChild(i)}
      ></span>
    ))}
  </div>
);
